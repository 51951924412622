import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import AnimeFadeIn from "@components/anime/AnimeFadeIn";

const variants = {
  visible: {
    opacity: 1,
    ease: "tween",
    transition: {
      duration: 0,
    },
  },
  hidden: {
    opacity: 0,
    ease: "tween",
    transition: {
      duration: 0,
    },
  },
};

const AnimeTeamMemberImage = ({ images, selected, hovered }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const [switchIndex, setSwitchIndex] = useState(1);

  useEffect(() => {
    if (selected) {
      if (switchIndex === 1) {
        setImgIndex(1);
        setSwitchIndex(2);
      } else {
        setImgIndex(2);
        setSwitchIndex(1);
      }
    } else {
      setImgIndex(0);
    }
  }, [selected]);

  return (
    <AnimeFadeIn>
      <AnimatePresence>
        <motion.div className="h-full w-full relative">
          <img className="opacity-0" src={images[imgIndex]}></img>
          <motion.img
            key={imgIndex}
            className={`absolute object-cover left-0 top-0 transition-all duration-500 ${
              hovered ? "" : "filter grayscale"
            }`}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            src={images[imgIndex]}
          ></motion.img>
        </motion.div>
      </AnimatePresence>
    </AnimeFadeIn>
  );
};

export default AnimeTeamMemberImage;
