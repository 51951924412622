import React, { useState } from "react";
import { AnimateSharedLayout, motion } from "framer-motion";
import AnimeFadeIn from "@components/anime/AnimeFadeIn";
import ProjectImageExpand from "@components/project/ProjectImageExpand";

const ProjectImage = ({ project, setDisplayFooter }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="overflow-hidden">
      <AnimeFadeIn>
        <AnimateSharedLayout>
          {open ? (
            <ProjectImageExpand project={project} next={open} animateDuration={0.5} />
          ) : (
            <motion.div
              layoutId="expandable-card"
              className={`max-h-full aspect-w-16 aspect-h-9 cursor-pointer ${
                project.colspan === 4
                  ? "lg:aspect-w-16 lg:aspect-h-9"
                  : project.colspan === 3 || project.colspan === 2
                  ? "lg:aspect-w-4 lg:aspect-h-3"
                  : "lg:aspect-w-1 lg:aspect-h-1"
              }`}
            >
              <motion.img
                ease="eastInOut"
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1 }}
                onClick={() => {
                  setOpen(true);
                  setDisplayFooter(false);
                }}
                className="object-cover"
                src={project.src[0]}
                alt={project.name + "-shrink"}
              />
            </motion.div>
          )}
        </AnimateSharedLayout>
      </AnimeFadeIn>
    </div>
  );
};

export default ProjectImage;
