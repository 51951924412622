import React, { useEffect } from "react";
import useState from "react-usestateref";
import ProjectCellGroup from "@components/project/ProjectCellGroup";
import Footer from "@components/layout/Footer/Footer";
import Nav from "@components/layout/Nav/Nav";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  const [displayFooter, setDisplayFooter] = useState(true);
  const [wheelDelta, setWheelDelta] = useState(0);
  const [hitBot, setHitBot] = useState(false);
  const [footerActive, setFooterActive] = useState(false);
  const [homeDesktopVideoUrl, setHomeDesktopVideoUrl] = useState({ url: "" });
  const [homeMobileVideoUrl, setHomeMobileVideoUrl] = useState({ url: "" });

  useEffect(async () => {
    const homeDesktopVideoUrlData = await fetch("/assets/general/video-url.json")
      .then((response) => response.json())
      .catch((e) => console.log(e));
    const homeMobileVideoUrlData = await fetch("/assets/general/video-mobile-url.json")
      .then((response) => response.json())
      .catch((e) => console.log(e));
    setHomeDesktopVideoUrl(homeDesktopVideoUrlData);
    setHomeMobileVideoUrl(homeMobileVideoUrlData);
  }, []);

  const handlers = useSwipeable({
    onSwiped: (e) => {
      const deltaY = e.deltaY;
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      if (window.scrollY > 0 && bottom) {
        setHitBot(true);
        setTimeout(() => {
          setFooterActive(true);
        }, 500);
      }
      setWheelDelta(deltaY);
    },
  });

  const handleWheel = (e) => {
    const deltaY = e.wheelDeltaY;
    setWheelDelta((prev) => {
      if (prev === deltaY) {
        return prev + 0.1;
      } else {
        return deltaY;
      }
    });
  };

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY < document.body.offsetHeight - 20) {
      setHitBot(false);
      setFooterActive(false);
    }

    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    if (window.scrollY > 0 && bottom) {
      setHitBot(true);
      setTimeout(() => {
        setFooterActive(true);
      }, 500);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheel);
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div {...handlers} className="sc">
      <Nav />
      <div className="fixed top-0 left-0 right-0 z-0">
        <div className="h-screen w-full relative">
          <div className="bg-black fixed w-full h-full">
            <video className="hidden lg:block absolute top-0 h-full w-full object-cover" src={homeDesktopVideoUrl.url} muted loop playsInline autoPlay />
            <video className="block lg:hidden absolute top-0 h-full w-full object-cover" src={homeMobileVideoUrl.url} muted loop playsInline autoPlay />
            {/* {window.pageYOffset > window.innerHeight * 2 && <video className="bg-white absolute top-0 h-full w-full object-cover" src="" />} */}
          </div>
          <div className="mx-5 lg:mx-10 grid-cols-1 lg:grid-cols-3 space-y-3 lg:space-y-0 absolute w-full bottom-24 lg:bottom-14 text-white grid grid-flow-row">
            <div className="text-base lg:text-lg col-span-1 font-semibold">WE ELEVATE BRANDS™</div>
            <div className="col-span-1"></div>
            <div className="col-span-1 text-xs font-extralight">
              [for all inquiries holla us at]
              <br />
              <a className="cursor-pointer underline" href="mailto:brainchild604@gmail.com">
                brainchild604@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="z-0 h-screen-height"></div>
      {/* Vimeo iframe for testing purpose */}
      {/* <div className="z-20 bg-white relative w-full h-screen">
        <iframe
          className="w-full h-full"
          src="https://player.vimeo.com/video/689104397?h=d7d3f6f664"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> */}
      <div className="z-20 bg-white relative">
        <div className="mx-5 lg:mx-10 gap-5 lg:gap-0 py-20 grid-cols-1 lg:grid-cols-3 grid grid-flow-row">
          <div className="col-span-1 text-2xl font-medium">
            We believe every idea
            <br />
            is the child of our
            <br />
            creative effort.
          </div>
          <div className="col-span-1"></div>
          <div className="col-span-1 text-2xl font-light">
            We are overachievers who believe in the power of creativity to bring values and elevate brands. We find thrills in building brand stories to fall in
            love with.
          </div>
        </div>

        <ProjectCellGroup setDisplayFooter={setDisplayFooter} />
      </div>
      {displayFooter && <Footer wheelDelta={wheelDelta} hitBot={hitBot} footerActive={footerActive} />}
    </div>
  );
};

export default Home;
