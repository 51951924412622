import React, { useState, useEffect } from "react";
import ProjectImage from "@components/project/ProjectImage";

const colsPerLoad = window.innerWidth < 1024 ? 16 : 24;
let projects = [];
let arrayForHoldingProjects = [];
let arrayForRemainingProjects = [];

const ProjectCellGroup = ({ setDisplayFooter }) => {
  const [projectsToShow, setProjectsToShow] = useState([]);
  const [loadText, setLoadText] = useState(`LOAD MORE\nPROJECTS`);
  const [next, setNext] = useState(0);
  const [hoverProject, setHoverProject] = useState("");

  const loopWithSlice = (start, end) => {
    const slicedProjects = projects.slice(start, end);
    arrayForHoldingProjects = [...arrayForHoldingProjects, ...slicedProjects];
    setProjectsToShow(arrayForHoldingProjects);
    if (arrayForHoldingProjects.length >= projects.length) {
      setLoadText("YOU HAVE\nREACHED\nTHE END");
    }
  };

  const calculateNextLoad = (curProjectArray) => {
    let totalColsCount = 0;
    let nextLoadIndex = 0;
    let rowColsCount = 0;
    curProjectArray.forEach((project) => {
      if (rowColsCount + project.colspan <= 4) {
        rowColsCount += project.colspan;
        totalColsCount += project.colspan;
        if (rowColsCount === 4) {
          rowColsCount = 0;
        }
      } else if (rowColsCount + project.colspan > 4) {
        totalColsCount += 4 - rowColsCount + project.colspan;
        rowColsCount = project.colspan;
      }

      if (totalColsCount <= colsPerLoad) {
        nextLoadIndex++;
      }
    });
    return nextLoadIndex;
  };

  useEffect(async () => {
    projects = await fetch("/assets/projects/projects.json").then((response) => response.json());
    projects.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date));
    arrayForHoldingProjects = [];
    arrayForRemainingProjects = projects;
    const nextLoadIndex = calculateNextLoad(projects);
    loopWithSlice(0, nextLoadIndex);
    setNext(nextLoadIndex);
    const slicedRemainingProjects = arrayForRemainingProjects.slice(nextLoadIndex, arrayForRemainingProjects.length);
    arrayForRemainingProjects = slicedRemainingProjects;
  }, []);

  const handleLoadMoreProjects = () => {
    const nextLoadIndex = calculateNextLoad(arrayForRemainingProjects);
    loopWithSlice(next, next + nextLoadIndex);
    setNext(next + nextLoadIndex);
    const slicedRemainingProjects = arrayForRemainingProjects.slice(nextLoadIndex, arrayForRemainingProjects.length);
    arrayForRemainingProjects = slicedRemainingProjects;
  };
  return (
    <>
      <div>
        <div className="mx-5 lg:mx-10 text-4xl lg:text-6xl font-medium mb-10">WORK</div>

        <div className="mx-5 lg:mx-10 grid-cols-1 lg:grid-cols-4 grid grid-flow-row gap-5 transition-all duration-300">
          {projectsToShow.map((project, index) => {
            const colspanClass = `lg:col-span-${project.colspan}`;
            return (
              <div
                className={colspanClass}
                key={index}
                onMouseEnter={() => {
                  setHoverProject(project.name);
                }}
                onMouseLeave={() => {
                  setHoverProject("");
                }}
              >
                <div className="grid grid-flow-row grid-cols-6 my-1">
                  <div
                    className={`col-span-5 justify-self-start transition-all duration-500 ${
                      hoverProject === project.name ? "text-brainchild-red" : "text-black"
                    }`}
                  >
                    <span className="text-lg font-medium">{project.name}</span>
                    {project.date && <sup className="mx-2 text-xs">{project.date.split("-")[0]}</sup>}
                  </div>
                  <div className="col-span-1 justify-self-end grid items-center justify-center">
                    <span
                      className={`h-3 w-3 rounded-full transition-all duration-500 ${hoverProject === project.name ? "bg-brainchild-red" : "bg-black"}`}
                    ></span>
                  </div>
                </div>
                <ProjectImage project={project} setDisplayFooter={setDisplayFooter} />
              </div>
            );
          })}
        </div>
      </div>

      <div className="grid items-center justify-center">
        <span
          onClick={handleLoadMoreProjects}
          className="h-28 w-28 lg:h-52 lg:w-52 my-32 lg:my-40 cursor-pointer rounded-full border-1 border-black grid items-center justify-center"
        >
          <p className="text-xs lg:text-sm text-center whitespace-pre-line">{loadText}</p>
        </span>
      </div>
    </>
  );
};

export default ProjectCellGroup;
