export const SET_PROJECTS = "SET_PROJECTS";

const initState = {
  projects: [],
};

export const Reducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return { ...state, projects: action.projects };

    default:
      return state;
  }
};
