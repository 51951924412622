import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { brainChildWhite, brainChildBlack, brainChildRed } from "@utils/constants.js";

const AnimeFadeIn = (props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("redBg");
    } else {
      controls.start("whiteBg");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="whiteBg"
      variants={{
        redBg: {
          backgroundColor: brainChildRed,
          color: brainChildWhite,
          stroke: brainChildWhite,
          fill: brainChildWhite,
          transition: {
            delay: 0.5,
            duration: 1,
            type: "tween",
          },
        },
        whiteBg: {
          backgroundColor: brainChildWhite,
          color: brainChildBlack,
          stroke: brainChildBlack,
          fill: brainChildBlack,
          transition: {
            type: "tween",
          },
        },
      }}
      {...props}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimeFadeIn;
