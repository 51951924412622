import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AnimeProjectTextFadeIn from "@components/anime/AnimeProjectTextFadeIn";
import { useSwipeable } from "react-swipeable";
import ScrollLock from "react-scrolllock";

const ProjectImageExpand = ({ project, next, animateDuration, animateVariants }) => {
  const history = useHistory();
  const [wheelDelta, setWheelDelta] = useState(0);
  const [bypass, setBypass] = useState(false);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      const delta = eventData.deltaY;
      setWheelDelta(delta);
    },
  });

  useEffect(() => {
    if (bypass && Math.abs(wheelDelta) > 40) {
      history.push(`/projects/${project.name}`);
    }
  }, [wheelDelta]);

  useEffect(() => {
    if (next) {
      setTimeout(() => {
        setBypass(true);
      }, 1000);
    }
  }, [next]);

  useEffect(() => {
    window.addEventListener("wheel", handleWheel);
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleWheel = (e) => {
    const delta = e.detail ? e.detail * -40 : e.wheelDelta;
    setWheelDelta(delta);
  };

  return (
    <>
      <ScrollLock isActive={true} />
      <motion.div
        {...handlers}
        onClick={() => {
          history.push(`/projects/${project.name}`);
        }}
        key={project.name}
        initial="hidden"
        animate="visible"
        transition={{ duration: animateDuration }}
        variants={animateVariants}
        layoutId="expandable-card"
        className="fixed inset-0 z-10 grid items-center justify-center"
      >
        <motion.img className="object-cover h-screen w-screen" src={project.src[0]} alt={project.name + "-expand"} />
        <AnimeProjectTextFadeIn className="fixed h-screen w-screen grid items-center justify-center bg-gradient-to-b from-transparent to-black">
          <div className="px-5 lg:px-80 text-5xl lg:text-6xl font-normal lg:font-medium leading-120 lg:leading-160 text-white text-center">{project.name}</div>
        </AnimeProjectTextFadeIn>
      </motion.div>
    </>
  );
};

export default ProjectImageExpand;
