import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { sectionVariants } from "./variants";
import { motion } from "framer-motion";
import AnimeTeamMemberImage from "@components/anime/AnimeTeamMemberImage";
import arrow_NE from "../../img/arrow_NE.png";
import "./StudioSectionAbout.scss";

const arrowVariant = {
  idle: {
    x: 0,
    y: 0,
    transition: {
      duration: 1,
    },
  },
  visible: {
    x: [-50, 0],
    y: [50, 0],
    transition: {
      duration: 1,
    },
  },
};

const StudioSectionAbout = ({ sectionIndex, projectData, setHitTop, setHitBot }) => {
  const history = useHistory();
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [selectTeamMember, setSelectTeamMember] = useState("");
  const [hoverTeamMember, setHoverTeamMember] = useState("");
  const [hoverServiceIndex, setHoverServiceIndex] = useState(-1);

  const [prevSectionIndex, setPrevSectionIndex] = useState(sectionIndex);
  const aboutSectionRef = useRef(null);

  useEffect(() => {
    if (prevSectionIndex === 3 && sectionIndex === 2) {
      window.scrollTo({
        top: aboutSectionRef.current.offsetHeight + window.innerHeight,
        left: 0,
        behavior: "instant",
      });

      setTimeout(() => {
        setHitTop(false);
        setHitBot(true);
      }, 1000);
    }

    if (prevSectionIndex === 1 && sectionIndex === 2) {
      setTimeout(() => {
        setHitTop(true);
        setHitBot(false);
      }, 1000);
    }

    if (prevSectionIndex === 2) {
      setTimeout(() => {
        aboutSectionRef.current.style.visibility = "hidden";
      }, 1000);
    } else if (sectionIndex === 2) {
      aboutSectionRef.current.style.visibility = "visible";
    }

    setPrevSectionIndex(sectionIndex);
  }, [sectionIndex]);

  useEffect(async () => {
    const serviceTypeList = await fetch("/assets/general/service-type.json").then((response) => response.json());
    const teamList = await fetch("/assets/teams/teams.json").then((response) => response.json());

    serviceTypeList.map((type, index) => {
      type.projects = [];
      projectData.forEach((project, projectIndex) => {
        if (type.projects.length < type.imgQty && project.serviceType.includes(type.name)) {
          type.projects.push(project);
        }
      });
      return type;
    });

    serviceTypeList.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order));

    teamList.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order));
    teamList.forEach((team) => {
      team.teamMembers.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order));
    });

    setServiceTypeData(serviceTypeList);
    setTeamData(teamList);
  }, [projectData]);

  const handleMemberOnClick = (teamMemberName) => {
    if (selectTeamMember === teamMemberName) {
      setSelectTeamMember("");
    } else {
      setSelectTeamMember(teamMemberName);
    }
  };

  return (
    <>
      <motion.div
        ref={aboutSectionRef}
        variants={sectionVariants}
        initial={false}
        animate={sectionIndex === 2 ? "visible" : sectionIndex > 2 ? "hiddenTop" : "hiddenBottom"}
        transition={{ duration: 1, ease: "easeInOut" }}
        className={`w-screen bg-white pb-60
        ${sectionIndex === 2 ? "" : "fixed"} 
        ${sectionIndex === 4 ? "hidden" : ""}`}
        style={{ bottom: sectionIndex === 3 ? 0 : "unset" }}
      >
        <div className="mx-5 lg:mx-0 grid-cols-1 lg:grid-cols-3 lg:h-screen grid grid-flow-row items-center">
          <div className="mt-40 lg:mt-0 lg:col-span-2 lg:ml-10 lg:py-20 text-2xl font-medium">
            We are your friendly
            <br />
            neighbourhood design
            <br />
            studio for any kinds of brand
            <br />
            visual and strategy
          </div>
          <div className="col-span-1 lg:mr-20 py-20 text-2xl font-light">
            By providing comprehensive design services that cater to client business needs, we have built a reputation for innovative work. 
            <br />
            Our mission is to creatively connect people and brands in meaningful ways.
          </div>
        </div>

        {serviceTypeData && (
          <div>
            <div className="mx-5 lg:mx-10 text-4xl lg:text-6xl font-medium">OUR SERVICE</div>
            <hr className="border-t-1 border-black my-10" />
            {serviceTypeData.map((serviceType, index) => {
              return (
                <div
                  onMouseEnter={() => {
                    setHoverServiceIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoverServiceIndex(-1);
                  }}
                  key={serviceType.name}
                  className="overflow-hidden mt-10"
                >
                  <div className="mx-5 lg:mx-10 grid-cols-1 lg:grid-cols-2 grid grid-flow-row gap-5">
                    <div className="grid-cols-3 grid grid-flow-row gap-5">
                      <div className="col-span-2 lg:col-span-1">
                        <p className="mb-5 lg:mb-10 uppercase">{serviceType.name}</p>
                        <p className="text-2xl lg:text-3xl font-medium lg:leading-120">{serviceType.description}</p>
                      </div>
                      <div className="col-span-1 lg:col-span-2 grid justify-end items-end">
                        <div className="overflow-hidden">
                          <motion.img
                            variants={arrowVariant}
                            initial="idle"
                            animate={hoverServiceIndex === index ? "visible" : ""}
                            src={arrow_NE}
                            alt="arrow_NE"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={`grid grid-cols-${serviceType.imgQty} grid-flow-row gap-5`}>
                      {serviceType.projects.map((project, projectIndex) => {
                        return (
                          <div className={`z-10 h-full ${serviceType.imgQty === 1 ? "aspect-w-4 aspect-h-3" : "aspect-w-1 aspect-h-1"}`} key={projectIndex}>
                            <img
                              className="object-cover cursor-pointer"
                              onClick={() => {
                                history.push(`/projects/${project.name}`);
                              }}
                              src={project.src[0]}
                              alt={projectIndex}
                            ></img>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <hr className="border-t-1 border-black mt-10" />
                </div>
              );
            })}
          </div>
        )}

        {teamData.map((team, teamIndex) => {
          return (
            <div
              className="mt-32 lg:mt-40 team-member-wrapper"
              onClick={(e) => {
                e.preventDefault();
                handleMemberOnClick("");
                setHoverTeamMember("");
              }}
              key={team.teamName}
            >
              <div className="grid-cols-1 lg:grid-cols-2 mx-5 lg:mx-10 lg:my-20 grid grid-flow-row">
                <div className="text-4xl lg:text-6xl font-medium">{team.teamName}</div>
                {teamIndex === 0 && (
                  <div className="text-sm lg:text-2xl lg:place-self-center my-5">
                    MEET THE <br />
                    CREATIVE <br />
                    MASTERMINDS
                  </div>
                )}
              </div>
              <div className="mx-5 lg:mx-10 grid-cols-2 lg:grid-cols-4 grid grid-flow-row gap-5">
                {team.teamMembers.map((teamMember, teamMemberIndex) => {
                  const infoRowStart = Math.ceil((teamMemberIndex + 1) / 4) + 1;
                  const infoRowStartMobile = Math.ceil((teamMemberIndex + 1) / 2) + 1;
                  const infoPos = teamMemberIndex % 4 === 0 || teamMemberIndex % 4 === 1 ? 1 : 3;
                  return (
                    <React.Fragment key={teamMember.name}>
                      <div
                        className={`col-span-1 cursor-pointer`}
                        onMouseLeave={() => setHoverTeamMember("")}
                        onMouseEnter={() => setHoverTeamMember(teamMember.name)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMemberOnClick(teamMember.name);
                          setHoverTeamMember(teamMember.name);
                        }}
                      >
                        <div>
                          <AnimeTeamMemberImage
                            images={teamMember.src}
                            selected={selectTeamMember === teamMember.name}
                            hovered={hoverTeamMember === teamMember.name}
                          />
                        </div>
                        <p className="text-2xl lg:text-3xl mt-2 lg:mt-5 font-medium">{teamMember.name}</p>
                        <p className="text-xs lg:text-base mb-5 font-light">[{teamMember.title}]</p>
                      </div>

                      {selectTeamMember === teamMember.name && (
                        <motion.div
                          initial={{ opacity: 0, y: -25 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.25 }}
                          className={`col-start-1 col-span-2 lg:col-span-4 row-start-${infoRowStartMobile} lg:row-start-${infoRowStart} grid grid-cols-2 lg:grid-cols-4 gap-5`}
                        >
                          <motion.div className={`whitespace-pre-line lg:col-start-${infoPos} col-span-2`}>{teamMember.info}</motion.div>
                        </motion.div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </motion.div>
    </>
  );
};

export default StudioSectionAbout;
