import React, { useState, useEffect } from "react";
import {
  sectionVariants,
  backgroundVariants,
  studioBgVariants,
  studioInitImage,
  studioInitTextVariants,
  svgVariants,
  studioInitTextVariantsFadeInAndOut,
} from "./variants";
import { motion } from "framer-motion";

const StudioSectionInit = ({ colorUpdate, sectionIndex }) => {
  const [studioHeaderData, setStudioHeaderData] = useState("");
  const [randomParameter, setRandomParameter] = useState("");
  const [studioDesktopVideoUrl, setStudioDesktopVideoUrl] = useState({ url: "" });
  const [studioMobileVideoUrl, setStudioMobileVideoUrl] = useState({ url: "" });

  useEffect(async () => {
    const studioDesktopVideoUrlData = await fetch("/assets/general/studio-header-desktop-video-url.json")
      .then((response) => response.json())
      .catch((e) => console.log(e));
    const studioMobileVideoUrlData = await fetch("/assets/general/studio-header-mobile-video-url.json")
      .then((response) => response.json())
      .catch((e) => console.log(e));
    setStudioDesktopVideoUrl(studioDesktopVideoUrlData);
    setStudioMobileVideoUrl(studioMobileVideoUrlData);
  }, []);

  useEffect(async () => {
    const data = await fetch("/assets/general/studio-header-image.json").then((response) => response.json());
    const studioHeaderImageUrl = data.src;

    setRandomParameter(createRandomParameter());
    setStudioHeaderData(studioHeaderImageUrl);
  }, []);

  const createRandomParameter = () => {
    const rotateInt = Math.floor(Math.random() * 10) + 1;
    const rotateDir = Math.random() > 0.5 ? "" : "-";
    const rotate = rotateDir + "rotate-" + rotateInt;
    return rotate;
  };

  return (
    <>
      <motion.div
        variants={sectionVariants}
        initial={false}
        animate={sectionIndex === 0 ? "visible" : "hiddenTop"}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="fixed w-screen h-screen"
        style={{ zIndex: sectionIndex > 0 ? -1 : "auto" }}
      >
        <motion.div
          variants={backgroundVariants}
          initial={false}
          transition={{ duration: 1, ease: "easeInOut" }}
          className={`z-0 fixed w-screen h-screen ${colorUpdate ? "bg-white" : "bg-black"}`}
        >
          <video className="hidden lg:block absolute top-0 h-full w-full object-cover" src={studioDesktopVideoUrl.url} muted loop playsInline autoPlay />
          <video className="block lg:hidden absolute top-0 h-full w-full object-cover" src={studioMobileVideoUrl.url} muted loop playsInline autoPlay />
        </motion.div>
        <motion.div
          variants={studioBgVariants}
          initial={false}
          animate={colorUpdate === 0 ? "hidden" : colorUpdate ? "visible" : "show"}
          transition={{ duration: 2, ease: "easeInOut" }}
          transitionEnd={{ display: "none" }}
          className="z-10 fixed h-full w-full flex items-center justify-center bg-white"
        >
          <motion.img
            variants={studioInitImage}
            initial={false}
            animate={colorUpdate ? "visible" : "hiddenBottom"}
            transition={{ duration: 1, ease: "easeInOut" }}
            className={"w-full h-full object-contain transform py-20 px-10 " + randomParameter}
            alt="studio1"
            src={studioHeaderData}
          />
        </motion.div>
        <motion.div
          variants={studioInitTextVariants}
          initial={false}
          animate={colorUpdate ? "transparent" : "white"}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="z-20 fixed w-full h-full flex flex-col space-y-10 items-center justify-center text-center"
        >
          <div className="font-medium lg:font-semibold leading-120 text-4xl lg:text-5xl">
            WE FLY IDEAS <br />
            TO THE MOON <br className="lg:hidden" />
          </div>
          <div className="flex items-center justify-center">
            <motion.svg
              variants={svgVariants}
              initial={false}
              animate={colorUpdate ? "transparent" : "white"}
              transition={{ duration: 1, ease: "easeInOut" }}
              width="72"
              height="74"
              viewBox="0 0 72 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.5459 42.4668L36.0459 68.9668L61.675 43.3377" strokeWidth="6" />
              <path d="M35.922 3L35.9212 68.843" strokeWidth="6" />
            </motion.svg>
          </div>
          <div className="font-medium lg:font-semibold leading-120 text-4xl lg:text-5xl">
            BRING THEM BACK <br />
            DOWN TO EARTH
          </div>
        </motion.div>
        <motion.div
          variants={studioInitTextVariantsFadeInAndOut}
          initial={false}
          animate={colorUpdate ? "visible" : "hiddenBottom"}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="z-20 fixed w-full h-full flex flex-col space-y-10 items-center justify-center text-center"
        >
          <div className="font-medium lg:font-semibold leading-120 text-4xl lg:text-5xl">
            AND PIN THEM <br />
            ON A POSTER
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default StudioSectionInit;
