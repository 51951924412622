import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import ReactFitText from "react-fittext";
import { useParams } from "react-router-dom";
import AnimeFadeIn from "@components/anime/AnimeFadeIn";
import AnimeProjectTextFadeIn from "@components/anime/AnimeProjectTextFadeIn";
import ProjectImageNext from "@components/project/ProjectImageNext";
import ProjectImageNextiOS from "@components/project/ProjectImageNextiOS";
import Nav from "@components/layout/Nav/Nav";
import ScrollLock from "react-scrolllock";
import store from "../../store";
import { useSwipeable } from "react-swipeable";
import { iOS } from "../../utils";

const Projects = (props) => {
  const data = store.getState().projects || [];
  const { _id = "" } = useParams();
  const [currentProject, setCurrentProject] = useState({ name: "" });
  const [nextProject, setNextProject] = useState();
  const [showNextProject, setShowNextProject] = useState(false);
  const [wheelDelta, setWheelDelta] = useState(0);
  const [hitbottom, setHitbottom] = useState(false);
  const [lockScroll, setLockScroll] = useState(true);
  const currentProjectRef = useRef(null);
  const [bodyHeight, setBodyHeight] = useState(0);
  // const [touchStartY, setTouchStartY, touchStartYRef] = useState(0);

  const handlers = useSwipeable({
    onSwiped: (e) => {
      const deltaY = e.deltaY;
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      if (window.scrollY > 0 && bottom) {
        setHitbottom(true);
        if (iOS) {
          setTimeout(() => {
            setShowNextProject(true);
          }, 500);
        }
      }
      setWheelDelta(deltaY);
    },
  });

  const handleWheel = (e) => {
    const deltaY = e.wheelDeltaY;
    setWheelDelta((prev) => {
      if (prev === deltaY) {
        return prev + 0.1;
      } else {
        return deltaY;
      }
    });
  };

  const handleScroll = (e) => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

    if (iOS) {
      if (window.scrollY > 0 && bottom) {
        setTimeout(() => {
          setHitbottom(true);
        }, 500);
      }

      if (window.innerHeight + window.scrollY < document.documentElement.scrollHeight && showNextProject) {
        setHitbottom(false);
        setShowNextProject(false);
      }
    }

    if (!iOS) {
      if (window.scrollY > 0 && bottom) {
        setTimeout(() => {
          setHitbottom(true);
        }, 500);
      }

      if (window.innerHeight + window.scrollY < document.documentElement.scrollHeight && !showNextProject) {
        setHitbottom(false);
      }
    }
  };

  useEffect(() => {
    if (hitbottom && !showNextProject && wheelDelta < -20) {
      setBodyHeight(document.documentElement.scrollHeight);
      setShowNextProject(true);
      if (!iOS) window.scroll(0, 1);
    }

    if (showNextProject && window.scrollY <= 0 && wheelDelta > 20) {
      setShowNextProject(false);
      setHitbottom(false);
      if (!iOS) window.scroll(0, bodyHeight);
    }

    if (!lockScroll && wheelDelta > 0) {
      setHitbottom(false);
    }
  }, [wheelDelta]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto",
    });

    setTimeout(() => {
      setLockScroll(false);
    }, 1200);
  }, []);

  useEffect(() => {
    if (_id && data) {
      setCurrentProject(data.find((project) => project.name === _id));
      const currentProjectIndex = data.findIndex((project) => project.name === _id);
      if (currentProjectIndex !== data.length - 1) {
        setNextProject(data[currentProjectIndex + 1]);
      } else {
        setNextProject(data[0]);
      }
    }
  }, [_id, data]);

  // function touchStart(event) {
  //   setTouchStartY(event.touches[0].screenY);
  // }

  // function touchMove(event) {
  //   setWheelDelta(event.touches[0].screenY - touchStartYRef.current);
  // }

  useEffect(() => {
    // window.addEventListener("touchstart", touchStart, false);
    // window.addEventListener("touchmove", touchMove, false);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("wheel", handleWheel);
    return () => {
      // window.removeEventListener("touchstart", touchStart, false);
      // window.removeEventListener("touchmove", touchMove, false);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const { name = "", date = "", spacing = false, src = [], descriptionLeft = "", descriptionRight = "", title = "", serviceType = [] } = currentProject || {};

  return (
    <>
      <ScrollLock isActive={lockScroll}></ScrollLock>
      <div {...handlers}>
        {/* <div className="fixed top-0 bg-transparent text-yellow-300 z-50 h-full w-full grid items-center justify-center pointer-events-none">
          <div>
            <div>is iOS?: {iOS.toString()}</div>
            <div>window.scrollY + innerHeight: {window.innerHeight + window.scrollY}</div>
            <div>document.documentElement.scrollHeight: {document.documentElement.scrollHeight}</div>
            <div>wheelDelta:{wheelDelta}</div>
            <div>hitbottom:{hitbottom.toString()}</div>
            <div>showNextProject:{showNextProject.toString()}</div>
            <div>lockScroll:{lockScroll.toString()}</div>
          </div>
        </div> */}
        <Nav />
        {!name && data.find((project) => project.name === _id) && (
          <img className="h-screen w-screen fixed object-cover" src={data.find((project) => project.name === _id).src[0]}></img>
        )}
        {name && (
          <>
            <div
              className={`bg-white ${!iOS && showNextProject ? "fixed" : ""}`}
              ref={currentProjectRef}
              style={{ top: !iOS && showNextProject ? -currentProjectRef.current.offsetHeight + window.innerHeight : "unset" }}
            >
              <div className="relative h-screen w-screen flex items-center justify-center overflow-hidden">
                <img className="h-full w-full object-cover" src={src[0]} alt={name} />
                <AnimeProjectTextFadeIn className="py-10 lg:py-24 grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-20 px-5 lg:px-10 absolute w-full bottom-0 text-white grid grid-flow-row bg-gradient-to-b from-transparent to-black">
                  <div className="grid-cols-2 lg:grid-cols-3 col-span-2 text-lg grid grid-flow-row">
                    <div className="text-sm lg:text-base font-normal">{date.split("-")[0]}</div>
                    <div className="text-sm lg:text-base items-baseline font-semibold grid grid-flow-row grid-cols-8">
                      <div className="h-2 w-2 lg:h-3 lg:w-3 col-span-1 rounded-full border-1 border-white bg-white"></div>
                      <div className="col-span-7">{name}</div>
                    </div>
                  </div>
                  <div className="mt-10 lg:mt-0 col-span-1 text-sm font-light uppercase">
                    Service:
                    <br />
                    {serviceType.map((type, index, array) => {
                      if (index === array.length - 1) {
                        return <span key={index}>{type}</span>;
                      } else {
                        return <span key={index}>{type + ", "}</span>;
                      }
                    })}
                  </div>
                </AnimeProjectTextFadeIn>
              </div>

              <div className="m-5 lg:m-10 py-10 lg:py-20">
                <div className="grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-20 grid grid-flow-row">
                  <div className="text-sm lg:text-base font-light lg:font-medium lg:w-40 col-span-1">{title}</div>
                  <div className="text-xl lg:text-2xl col-span-1 font-medium">{descriptionLeft}</div>
                  <div className="text-xl lg:text-2xl col-span-1 font-light">{descriptionRight}</div>
                </div>

                {src.map((imageUrl, index) => {
                  if (index !== 0) {
                    return (
                      <div key={index}>
                        <img className={`${spacing ? "my-10" : ""} w-full`} src={imageUrl} />
                      </div>
                    );
                  }
                })}
              </div>

              <AnimeFadeIn>
                <div className="mt-20 lg:mt-40 flex flex-col items-center justify-center pb-20 gap-8">
                  <ReactFitText compressor={2}>
                    <p className="w-full text-center font-semibold whitespace-pre-line leading-110">
                      NEXT
                      <br />
                      PROJECT
                    </p>
                  </ReactFitText>
                  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="21" y1="3" x2="21" y2="37" stroke="black" strokeWidth="4" />
                    <path d="M2 19.0918L21.0919 38.1837L40.1838 19.0918" stroke="black" strokeWidth="4" />
                  </svg>
                  <ReactFitText compressor={6}>
                    <p className="w-full text-center font-semibold whitespace-pre-line leading-110">KEEP SCROLLING</p>
                  </ReactFitText>
                </div>
              </AnimeFadeIn>
            </div>
          </>
        )}

        {!iOS && showNextProject && (
          <ProjectImageNext project={nextProject} next={showNextProject} showNextProject={showNextProject} wheelDelta={wheelDelta} bodyHeight={bodyHeight} />
        )}

        {iOS && (
          <ProjectImageNextiOS project={nextProject} next={showNextProject} showNextProject={showNextProject} wheelDelta={wheelDelta} hitbottom={hitbottom} />
        )}
      </div>
    </>
  );
};

export default Projects;
