import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "@pages/Home/Home";
import Projects from "@pages/Projects/Projects";
import Studio from "@pages/Studio/Studio";
import store from "./store";
import { SET_PROJECTS } from "./store/Reducer.js";

const App = () => {
  useEffect(async () => {
    const list = await fetch("/assets/projects/projects.json").then((response) => response.json());
    list.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date));
    console.log(list);
    store.dispatch({
      type: SET_PROJECTS,
      projects: list,
    });
  }, []);

  return (
    <Router>
      <Route
        render={({ location }) => (
          <>
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route path="/projects/:_id" component={Projects} />
                <Route path="/studio" component={Studio} />
                <Route path="/" component={Home} />
                <Redirect to="/" component={Home} />
              </Switch>
            </AnimatePresence>
          </>
        )}
      />
    </Router>
  );
};

export default App;
