import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sectionVariants } from "./variants";
import { motion } from "framer-motion";
import "./StudioSectionProject.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Autoplay, Mousewheel } from "swiper";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay, Mousewheel]);

const StudioSectionProject = ({ sectionIndex, projectData }) => {
  const history = useHistory();

  return (
    <>
      <motion.div
        variants={sectionVariants}
        initial={false}
        animate={sectionIndex === 1 ? "visible" : sectionIndex > 1 ? "hiddenTop" : "hiddenBottom"}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="fixed h-screen bg-white flex items-center"
        style={{ zIndex: sectionIndex > 1 ? -1 : "auto" }}
      >
        {projectData && (
          <Swiper
            onAutoplayStop={(swiper) => {
              setTimeout(() => {
                swiper.slideTo(0, 0);
                setTimeout(() => {
                  if (swiper.autoplay) {
                    swiper.autoplay.start();
                  }
                }, 100);
              }, 500);
            }}
            className="mx-5 lg:mx-10 py-10"
            slidesPerView={"auto"}
            spaceBetween={20}
            freeMode={true}
            // loop={true}
            loopedSlides={projectData.length}
            mousewheel={true}
            autoplay={{
              delay: 100,
              stopOnLastSlide: true,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
            }}
            speed={4000}
            style={{
              cursor: sectionIndex === 1 ? "url(https://brainchilddev.s3.us-west-2.amazonaws.com/static/drag.png), auto" : "",
            }}
          >
            {projectData.map((project) => {
              return (
                <SwiperSlide key={project.name} style={{ width: "unset" }}>
                  <img
                    className="h-80 w-80 object-cover"
                    onClick={() => {
                      history.push(`/projects/${project.name}`);
                    }}
                    src={project.src[0]}
                  ></img>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </motion.div>
    </>
  );
};

export default StudioSectionProject;
