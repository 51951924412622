import { brainChildWhite, brainChildBlack, brainChildRed } from "@utils/constants.js";

export const sectionVariants = {
  visible: { y: 0 },
  hiddenBottom: { y: window.innerHeight },
  hiddenTop: { y: window.innerWidth > 1024 ? -window.innerHeight : -document.documentElement.clientHeight },
};
export const backgroundVariants = {
  red: { backgroundColor: brainChildRed },
  white: { backgroundColor: brainChildWhite },
};
export const imgVariants = {
  hidden: { opacity: 0, y: window.innerHeight },
  visible: { opacity: 1, y: 0 },
  show: { opacity: 1, y: 0, transition: { duration: 0, delay: 0 } },
};
export const svgVariants = {
  white: { stroke: brainChildWhite },
  black: { stroke: brainChildBlack },
};
export const textVariants = {
  white: { color: brainChildWhite, opacity: 1 },
  black: { color: brainChildBlack, opacity: 1 },
  transparent: { opacity: 0 },
};
export const studioBgVariants = {
  hidden: { clipPath: "ellipse(50% 0% at 50% 200%)" },
  visible: { clipPath: ["ellipse(50% 0% at 50% 200%)", "ellipse(100% 150% at 50% 160%)", "ellipse(500% 100% at 50% 80%)"] },
  show: { clipPath: ["ellipse(500% 100% at 50% 80%)", "ellipse(100% 150% at 50% 160%)", "ellipse(0% 0% at 50% 200%)"] },
};
export const studioInitImage = {
  visible: { y: 0 },
  hiddenBottom: { y: window.innerHeight },
};
export const studioInitTextVariants = {
  white: { color: brainChildWhite, opacity: 1, transition: { duration: 1, delay: 0.5, ease: "easeInOut" } },
  black: { color: brainChildBlack, opacity: 1 },
  transparent: { opacity: 0 },
};
export const studioInitTextVariantsFadeInAndOut = {
  visible: { color: brainChildBlack, opacity: 1, y: 0, transition: { duration: 1, delay: 0.5, ease: "easeInOut" } },
  hiddenBottom: { opacity: 0, y: window.innerHeight, transition: { duration: 1, ease: "easeInOut" } },
};
export const studioContactTextVariants = {
  visible: { y: 0, transition: { duration: 1, delay: 0.5, ease: "easeInOut" } },
  hiddenBottom: { y: window.innerHeight },
  hiddenTop: { y: -window.innerHeight },
};
