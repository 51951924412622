import React, { useState, useEffect } from "react";
import { textVariants } from "./variants";
import { motion, AnimateSharedLayout } from "framer-motion";
import { iOS } from "../../utils";

const studioList = [
  {
    setTo: 1,
    title: "PROJECT",
  },
  {
    setTo: 2,
    title: "ABOUT",
  },
  {
    setTo: 3,
    title: "CONTACT",
  },
  {
    setTo: 4,
    title: "CAREER",
  },
];

const StudioFooter = ({ colorUpdate, sectionIndex, setSectionIndex }) => {
  const [navGap, setNavGap] = useState(40);
  const [navMargin, setNavMargin] = useState(110);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setNavGap(20);
      setNavMargin(86);
    } else {
      setNavGap(40);
      setNavMargin(110);
    }
  }, [sectionIndex]);

  return (
    <>
      <motion.div
        variants={textVariants}
        initial={false}
        animate={colorUpdate ? "black" : "white"}
        transition={{ duration: 1, ease: "linear" }}
        className={`fixed z-50 bottom-0 w-screen grid grid-flow-row grid-cols-1 lg:grid-cols-2 items-center`}
      >
        <motion.ul className="mx-5 lg:mx-10 py-5 lg:py-10 lg:justify-self-start lg:place-self-start grid grid-flow-row grid-cols-4 lg:grid-cols-1 justify-center">
          {studioList.map((studioItem) => {
            return (
              <motion.div key={studioItem.title}>
                <AnimateSharedLayout>
                  {sectionIndex < studioItem.setTo && (
                    <motion.li
                      layoutId={`layout-${studioItem.setTo}`}
                      className={`cursor-pointer text-center lg:text-left text-xs lg:text-base z-50`}
                      onClick={() => setSectionIndex(studioItem.setTo)}
                    >
                      {studioItem.title}
                    </motion.li>
                  )}
                  {!iOS && sectionIndex >= studioItem.setTo && (
                    <motion.div
                      layoutId={`layout-${studioItem.setTo}`}
                      className={`fixed z-50 top-20 right-0 lg:top-28 w-20 lg:w-24 text-xs lg:text-base text-center border-black border-1 rounded-full ${
                        sectionIndex === studioItem.setTo ? "bg-black text-white" : "cursor-pointer bg-white"
                      }`}
                      style={{
                        left: (studioItem.setTo - 1) * navMargin + navGap + "px",
                      }}
                      onClick={() => {
                        if (sectionIndex !== studioItem.setTo) setSectionIndex(studioItem.setTo);
                      }}
                    >
                      {studioItem.title}
                    </motion.div>
                  )}
                </AnimateSharedLayout>
              </motion.div>
            );
          })}
        </motion.ul>
        <div className="hidden lg:block mx-10 py-10 justify-self-end place-self-end">VISUAL BRANDING STRATEGIST</div>
      </motion.div>

      {iOS &&
        studioList.map((studioItem) => {
          return (
            <>
              {sectionIndex >= studioItem.setTo && (
                <motion.div
                  layoutId={`layout-${studioItem.setTo}`}
                  className={`fixed z-50 top-20 right-0 lg:top-28 w-20 lg:w-24 text-xs lg:text-base text-center border-black border-1 rounded-full ${
                    sectionIndex === studioItem.setTo ? "bg-black text-white" : "cursor-pointer bg-white"
                  }`}
                  style={{
                    left: (studioItem.setTo - 1) * navMargin + navGap + "px",
                  }}
                  onClick={() => {
                    if (sectionIndex !== studioItem.setTo) setSectionIndex(studioItem.setTo);
                  }}
                >
                  {studioItem.title}
                </motion.div>
              )}
            </>
          );
        })}
    </>
  );
};

export default StudioFooter;
