import React, { useEffect } from "react";
import useState from "react-usestateref";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AnimeProjectTextFadeIn from "@components/anime/AnimeProjectTextFadeIn";
import ScrollLock from "react-scrolllock";

const ProjectImageNext = ({ project, showNextProject, wheelDelta, hitbottom }) => {
  const history = useHistory();
  const [bypass, setBypass] = useState(false);
  const [fixedPosition, setFixedPosition] = useState("top-full");
  const [lockScroll, setLockScroll] = useState(false);
  const [pageTransitionLock, setPageTransitionLock] = useState(false);

  useEffect(() => {
    if (!pageTransitionLock && hitbottom && showNextProject && wheelDelta < -20) {
      setFixedPosition("top-0");
      setLockScroll(true);
      setTimeout(() => {
        setBypass(true);
      }, 500);
    } else if (
      (!pageTransitionLock && hitbottom && showNextProject && wheelDelta > 20) ||
      (!pageTransitionLock && fixedPosition === "top-0" && wheelDelta > 20)
    ) {
      setFixedPosition("top-full");
      setBypass(false);
      setTimeout(() => {
        setLockScroll(false);
      }, 1000);
    }
  }, [wheelDelta]);

  useEffect(() => {
    if (bypass && fixedPosition === "top-0" && wheelDelta < -40) {
      setPageTransitionLock(true);
      history.push(`/projects/${project.name}`);
    }
  }, [wheelDelta]);

  return (
    <>
      <ScrollLock isActive={lockScroll} />
      <div className={`z-30 fixed h-full w-full transition-all duration-500 ${fixedPosition}`}>
        <motion.div
          onClick={() => {
            if (bypass) history.push(`/projects/${project?.name}`);
          }}
          key={project?.name}
          className="relative h-full w-full"
        >
          <AnimeProjectTextFadeIn className="absolute h-full w-full grid items-center justify-center bg-gradient-to-b from-transparent to-black transition-all duration-75">
            <div className="px-5 lg:px-80 text-5xl lg:text-6xl font-normal lg:font-medium leading-120 lg:leading-160 text-white text-center">
              {project?.name}
            </div>
          </AnimeProjectTextFadeIn>
          <motion.img className="object-cover h-full w-full" src={project?.src[0]} alt={project?.name + "-expand"} />
        </motion.div>
      </div>
    </>
  );
};

export default ProjectImageNext;
