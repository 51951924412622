import React, { useEffect } from "react";
import useState from "react-usestateref";
import ScrollLock from "react-scrolllock";
import Nav from "@components/layout/Nav/Nav";
import StudioFooter from "@components/studio/StudioFooter";
import StudioSectionInit from "@components/studio/StudioSectionInit";
import StudioSectionProject from "@components/studio/StudioSectionProject";
import StudioSectionAbout from "@components/studio/StudioSectionAbout";
import StudioSectionContact from "@components/studio/StudioSectionContact";
import StudioSectionCareer from "@components/studio/StudioSectionCareer";
import Footer from "@components/layout/Footer/Footer";
import { useSwipeable } from "react-swipeable";

const Studio = () => {
  const [projectData, setProjectData] = useState([]);

  useEffect(async () => {
    const projectList = await fetch("/assets/projects/projects.json").then((response) => response.json());
    projectList.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date));
    setProjectData(projectList);
  }, []);

  const [wheelDelta, setWheelDelta] = useState(0);
  const [wheelDirection, setWheelDirection] = useState("");
  const [hitBot, setHitBot] = useState(true);
  const [hitTop, setHitTop] = useState(true);
  const [colorUpdate, setColorUpdate] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [lock, setLock, lockRef] = useState(false);
  const [lockScroll, setLockScroll] = useState(true);
  const [footerActive, setFooterActive] = useState(false);

  const handlers = useSwipeable({
    onSwiped: (onSwipedData) => {
      const top = window.scrollY === 0;
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      if (window.scrollY > 0 && bottom) {
        setHitBot(true);
        setTimeout(() => {
          setFooterActive(true);
        }, 500);
      }
      if (top) {
        setHitTop(true);
      }

      if (!lockRef.current) {
        const delta = onSwipedData.deltaY;

        let direction;
        if (delta !== null) {
          direction = delta > 0 ? "up" : "down";
        }
        setLockScroll(false);
        setWheelDirection(direction);
        setWheelDelta(delta);
      }
    },
  });

  const handleWheel = (e) => {
    if (!lockRef.current) {
      const delta = e.detail ? e.detail * -120 : e.wheelDeltaY;
      setWheelDelta((prev) => {
        if (prev === delta) {
          return prev + 0.1;
        } else {
          return delta;
        }
      });

      let direction;
      if (delta !== null) {
        direction = delta > 0 ? "up" : "down";
      }
      setWheelDirection(direction);
    }
  };

  const handleScroll = () => {
    const top = window.scrollY === 0;
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    if (window.scrollY > 0 && bottom) {
      setHitBot(true);
      setTimeout(() => {
        setFooterActive(true);
      }, 500);
    }
    if (top) {
      setHitTop(true);
    }
  };

  const nextSection = () => {
    if (sectionIndex < 4 && hitBot) {
      setSectionIndex((prev) => {
        return prev + 1;
      });
      setLock(true);
      setHitTop(false);
      setHitBot(false);
      setTimeout(() => {
        setLock(false);
      }, 1000);
    }
  };

  const prevSection = () => {
    if (sectionIndex > 0 && hitTop) {
      setSectionIndex((prev) => {
        return prev - 1;
      });
      setLock(true);
      setHitTop(false);
      setHitBot(false);
      setTimeout(() => {
        setLock(false);
      }, 1000);
    } else if (sectionIndex === 0) {
      setColorUpdate(false);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheel);
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!colorUpdate && sectionIndex !== 0) {
      setColorUpdate(true);
    }

    if (sectionIndex === 2) {
      setTimeout(() => {
        setLockScroll(false);
      }, 1000);
    } else if (sectionIndex === 4) {
      setTimeout(() => {
        setLockScroll(false);
        setHitTop(true);
        setHitBot(false);
      }, 1000);
    } else {
      setLockScroll(true);
      setHitTop(true);
      setHitBot(true);
    }

    setWheelDirection("");
  }, [sectionIndex, colorUpdate]);

  useEffect(() => {
    if (Math.abs(wheelDelta) > 40 && !lock) {
      if (sectionIndex === 0 || sectionIndex === 1 || sectionIndex === 3) {
        setHitBot(true);
        setHitTop(true);
      }

      if (wheelDirection === "down") {
        if (hitTop) {
          setHitTop(false);
        }
        if (!colorUpdate) {
          setColorUpdate(true);
          setLock(true);
          setTimeout(() => {
            setLock(false);
          }, 2000);
        } else {
          nextSection();
        }
      }

      if (wheelDirection === "up") {
        if (hitBot) {
          setHitBot(false);
          setFooterActive(false);
        }
        prevSection();
      }
    }
  }, [wheelDelta]);

  return (
    <div {...handlers} className="bg-white">
      <ScrollLock isActive={lockScroll} />

      <Nav colorUpdate={colorUpdate} sectionIndex={sectionIndex} />

      <StudioSectionInit colorUpdate={colorUpdate} sectionIndex={sectionIndex} />

      <StudioSectionProject sectionIndex={sectionIndex} projectData={projectData} />

      <StudioSectionAbout sectionIndex={sectionIndex} projectData={projectData} setHitTop={setHitTop} setHitBot={setHitBot} />

      <StudioSectionContact sectionIndex={sectionIndex} />

      <StudioSectionCareer sectionIndex={sectionIndex} />

      <StudioFooter colorUpdate={colorUpdate} sectionIndex={sectionIndex} setSectionIndex={setSectionIndex} />

      {sectionIndex === 4 && <Footer wheelDelta={wheelDelta} hitBot={hitBot} footerActive={footerActive} />}
    </div>
  );
};

export default Studio;
