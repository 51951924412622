import React from "react";
import { motion } from "framer-motion";

const AnimeProjectTextFadeIn = (props) => {
  return (
    <motion.div
      exit="hidden"
      animate="visible"
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          ease: "eastInOut",
          transition: {
            duration: 0.6,
            delay: 0.5,
            type: "tween",
          },
        },
        hidden: {
          opacity: 0,
          y: 200,
          ease: "eastInOut",
          transition: {
            duration: 0.6,
            delay: 0.5,
            type: "tween",
          },
        },
      }}
      {...props}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimeProjectTextFadeIn;
