import React, { useState, useEffect } from "react";
import { sectionVariants, imgVariants, studioContactTextVariants } from "./variants";
import { motion } from "framer-motion";
import ReactFitText from "react-fittext";

const paddings = [24, 28, 32, 36, 40, 48, 52, 60];

const StudioSectionContact = ({ sectionIndex }) => {
  const [contactData, setContactData] = useState([]);
  const [randomParameter, setRandomParameter] = useState([]);

  useEffect(async () => {
    const list = await fetch("/assets/general/flyover-images.json").then((response) => response.json());
    list.forEach(() => {
      (oldArray) => [...oldArray, newElement];
      setRandomParameter((prev) => [...prev, createRandomParameter()]);
    });
    setContactData(list);
  }, []);

  const createRandomParameter = () => {
    const rotateInt = Math.floor(Math.random() * 20) + 1;
    const rotateDir = Math.random() > 0.5 ? "" : "-";
    const rotate = rotateDir + "rotate-" + rotateInt;
    const paddingIndex = Math.floor(Math.random() * paddings.length);
    const py = paddings[paddingIndex];
    return { rotate: rotate, py: py };
  };
  return (
    <>
      <motion.div
        variants={sectionVariants}
        initial={false}
        animate={sectionIndex === 3 ? "visible" : sectionIndex > 3 ? "hiddenTop" : "hiddenBottom"}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="fixed w-screen h-screen bg-white z-0"
        style={{ zIndex: sectionIndex > 3 ? -1 : "auto" }}
      >
        {contactData &&
          contactData.map((imgUrl, index) => {
            return (
              <motion.div
                key={index}
                variants={imgVariants}
                initial={"hidden"}
                animate={sectionIndex >= 3 ? (sectionIndex === 3 ? "visible" : "show") : ""}
                transition={{ duration: 1, ease: "easeInOut", delay: 1 + 3 * index }}
                className="fixed h-screen w-screen flex items-center justify-center z-0"
              >
                <img
                  className={`w-full h-full object-contain transform ${randomParameter[index].rotate} py-${randomParameter[index].py} px-10 scale-90`}
                  alt={imgUrl}
                  src={imgUrl}
                ></img>
              </motion.div>
            );
          })}

        <ReactFitText compressor={2.5}>
          <motion.div
            variants={studioContactTextVariants}
            initial={false}
            animate={sectionIndex === 3 ? "visible" : sectionIndex > 3 ? "hiddenTop" : "hiddenBottom"}
            transition={{ duration: 1, ease: "easeInOut" }}
            className="hidden lg:flex fixed border-black text-center font-medium w-full h-full items-center justify-center"
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
              href="https://www.google.ca/maps/place/2366+Simpson+Rd,+Richmond,+BC+V6X+2P9/@49.1956771,-123.1127307,14.15z/data=!4m5!3m4!1s0x548675017258aeb3:0xa381e246054e12ac!8m2!3d49.1966752!4d-123.0983662"
            >
              32.7140929 N, -117.1525098 W
            </a>
          </motion.div>
        </ReactFitText>

        <motion.div className="flex lg:hidden fixed border-black text-center font-medium w-full h-full items-center justify-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-5xl"
            href="https://www.google.ca/maps/place/2366+Simpson+Rd,+Richmond,+BC+V6X+2P9/@49.1956771,-123.1127307,14.15z/data=!4m5!3m4!1s0x548675017258aeb3:0xa381e246054e12ac!8m2!3d49.1966752!4d-123.0983662"
          >
            32.7140929 N, -117.1525098 W
          </a>
        </motion.div>
      </motion.div>
    </>
  );
};

export default StudioSectionContact;
