import React, { useEffect } from "react";
import useState from "react-usestateref";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import AnimeProjectTextFadeIn from "@components/anime/AnimeProjectTextFadeIn";
import ScrollLock from "react-scrolllock";

const ProjectImageNext = ({ project, showNextProject, wheelDelta, bodyHeight }) => {
  const history = useHistory();
  const [bypass, setBypass] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);

  const handleScroll = (e) => {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight && showNextProject) {
      setLockScreen(true);
      setTimeout(() => {
        setBypass(true);
      }, 600);
    }
  };

  useEffect(() => {
    if (bypass && Math.abs(wheelDelta) > 40) {
      history.push(`/projects/${project.name}`);
    }
  }, [wheelDelta]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <ScrollLock isActive={lockScreen} />
      <div style={{ height: window.pageYOffset < 200 ? bodyHeight : "unset" }}>
        <div className="h-screen w-screen bg-transparent"></div>
        <div className="hidden lg:block h-60 w-screen bg-transparent"></div>
        <motion.div
          onClick={() => {
            if (bypass) history.push(`/projects/${project.name}`);
          }}
          key={project.name}
          className="relative inset-0 z-10 grid justify-center items-center transition-all duration-75"
        >
          <motion.img className="object-cover h-screen w-screen" src={project.src[0]} alt={project.name + "-expand"} />
          <AnimeProjectTextFadeIn className="absolute h-full w-full grid items-center justify-center bg-gradient-to-b from-transparent to-black transition-all duration-75">
            <div className="px-5 lg:px-80 text-5xl lg:text-6xl font-normal lg:font-medium leading-120 lg:leading-160 text-white text-center">
              {project.name}
            </div>
          </AnimeProjectTextFadeIn>
        </motion.div>
      </div>
    </>
  );
};

export default ProjectImageNext;
