import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMousePosition } from "@utils/hooks/index";
import { motion } from "framer-motion";

const r = 28 - 6;
const circleCenter = 71;

const Nav = ({ colorUpdate, sectionIndex }) => {
  const location = useLocation();
  const mousePosition = useMousePosition();
  const [dotPosition, setDotPosition] = useState({ top: 0, right: 0 });
  const [circleActive, setCircleActive] = useState({ work: false, studio: false });

  useEffect(() => {
    // calculate the distance from circle center to mouse position
    const distX = mousePosition.mouseX - (window.innerWidth - circleCenter);
    const distY = mousePosition.mouseY - circleCenter;
    const distance = Math.sqrt(distX * distX + distY * distY);

    if (distance < r) {
      // if mouse is inside of the circle, then use mouse position
      const top = mousePosition.mouseY - 5;
      const right = window.innerWidth - mousePosition.mouseX - 5;

      setDotPosition({ top: top, right: right });
    } else {
      // if mouse is outside, calculate the unit vector
      const vectorX = distX / distance;
      const vectorY = distY / distance;

      setDotPosition({
        top: vectorY * r + circleCenter - 5,
        right: -vectorX * r + circleCenter - 5,
      });
    }
  }, [mousePosition]);

  useEffect(() => {
    if (location.pathname !== "/studio") {
      setCircleActive({
        work: "show",
        studio: false,
      });
    } else if (location.pathname === "/studio") {
      setCircleActive({
        work: false,
        studio: "show",
      });
    }
  }, [location.pathname]);

  const handleMouseEnter = (navName) => {
    setCircleActive((prev) => {
      if (prev[navName] !== "show") {
        return {
          ...prev,
          [navName]: "hovering",
        };
      } else {
        return prev;
      }
    });
  };
  const handleMouseLeave = (navName) => {
    setCircleActive((prev) => {
      if (prev[navName] !== "show") {
        return {
          ...prev,
          [navName]: false,
        };
      } else {
        return prev;
      }
    });
  };

  return (
    <>
      <motion.div
        className={`fixed left-0 top-0 right-0 z-40 w-full grid grid-flow-row grid-cols-2 items-center ${
          sectionIndex >= 0
            ? sectionIndex === 0
              ? colorUpdate
                ? "text-white mix-blend-difference"
                : "text-white"
              : "text-white mix-blend-difference"
            : "text-white mix-blend-difference"
        }`}
      >
        <div className="mx-5 lg:mx-10 py-5 lg:py-10 text-2xl lg:text-3xl justify-self-start place-self-start font-semibold transition-all duration-500">
          <a href="/" className="cursor-pointer">
            BRAINCHILD
          </a>
        </div>
        <div className="relative mx-5 lg:mx-10 space-x-5 lg:space-x-10 py-5 lg:py-10 justify-self-end text-xl flex flex-row items-center">
          <motion.span
            className={`absolute z-0 h-2 w-2 lg:h-3 lg:w-3 rounded-full border-1 transition-all duration-500
          ${
            circleActive.work === "hovering"
              ? "left-2 lg:left-6"
              : circleActive.studio === "hovering"
              ? "left-16 lg:left-26"
              : circleActive.work === "show"
              ? "left-2 lg:left-6"
              : circleActive.studio === "show"
              ? "left-16 lg:left-26"
              : "opacity-0"
          } 
          ${
            sectionIndex >= 0
              ? sectionIndex === 0
                ? colorUpdate
                  ? "border-white bg-white"
                  : "border-white bg-white"
                : "border-white bg-white"
              : "border-white bg-white"
          }`}
          ></motion.span>
          <div className={`z-10 flex flex-row justify-center items-center space-x-2 transition-all duration-500`}>
            <span onMouseEnter={() => handleMouseEnter("work")} onMouseLeave={() => handleMouseLeave("work")} className={`text-xs lg:text-sm font-light`}>
              <a href="/">WORK</a>
            </span>
          </div>
          <div className={`mr-0 lg:mr-5 flex flex-row justify-center items-center space-x-2 transition-all duration-500`}>
            <span onMouseEnter={() => handleMouseEnter("studio")} onMouseLeave={() => handleMouseLeave("studio")} className={`text-xs lg:text-sm font-light`}>
              <a href="/studio">STUDIO</a>
            </span>
          </div>
          <motion.div
            className={`hidden lg:block h-14 w-14 rounded-full border-1 transition-all duration-500 ${
              sectionIndex >= 0 ? (sectionIndex === 0 ? (colorUpdate ? "border-white" : "border-white") : "border-white") : "border-white"
            }`}
          ></motion.div>
        </div>
      </motion.div>
      <div
        className={`hidden lg:block fixed text-2xl rounded-full h-1 w-1 z-50 transition-colors duration-500 ${
          sectionIndex >= 0
            ? sectionIndex === 0
              ? colorUpdate
                ? "bg-white mix-blend-difference"
                : "bg-white"
              : "bg-white mix-blend-difference"
            : "bg-white mix-blend-difference"
        }
        `}
        style={{
          top: dotPosition.top + "px",
          right: dotPosition.right + "px",
        }}
      ></div>
    </>
  );
};

export default Nav;
