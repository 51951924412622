import React, { useEffect, useState } from "react";
import ReactFitText from "react-fittext";
import { motion } from "framer-motion";
import AnimeFooterColor from "@components/anime/AnimeFooterColor";
import ScrollLock from "react-scrolllock";

const footerList = ["SAY HELLO", "HIRE US", "JOIN US"];

const arrowVariant = {
  idle: {
    x: 0,
    y: 0,
    transition: {
      duration: 1,
    },
  },
  visible: {
    x: [-50, 0],
    y: [50, 0],
    transition: {
      duration: 1,
    },
  },
};

const Footer = ({ wheelDelta, hitBot, footerActive }) => {
  const [hoverItem, setHoverItem] = useState("");
  const [fixedPosition, setFixedPosition] = useState("top-full");
  const [lockScroll, setLockScroll] = useState(false);

  useEffect(() => {
    if (hitBot && footerActive && wheelDelta < -20) {
      setFixedPosition("top-0");
      setLockScroll(true);
    } else if ((hitBot && footerActive && wheelDelta > 20) || (fixedPosition === "top-0" && wheelDelta > 20)) {
      setFixedPosition("top-full");
      setTimeout(() => {
        setLockScroll(false);
      }, 1000);
    }
  }, [wheelDelta]);

  return (
    <>
      {/* <div className="fixed top-0 bg-transparent text-yellow-300 z-50 h-screen w-screen grid items-center justify-center pointer-events-none">
        <div>
          <div>wheelDelta:{wheelDelta}</div>
          <div>hitBot:{hitBot.toString()}</div>
          <div>fixedPosition:{fixedPosition}</div>
          <div>lockScroll:{lockScroll.toString()}</div>
        </div>
      </div> */}
      <ScrollLock isActive={lockScroll} />
      <AnimeFooterColor className={`z-50 fixed h-screen w-screen transition-all duration-500 ${fixedPosition}`}>
        <div className="h-full lg:grid lg:grid-flow-row flex flex-col">
          <div className="grid grid-flow-row grid-cols-2 items-center">
            <div className="text-2xl lg:text-3xl mx-5 lg:mx-10 py-6 lg:py-10 justify-self-start font-semibold">BRAINCHILD</div>
            <div className="mx-5 lg:mx-10 py-6 lg:py-10 space-x-2 lg:space-x-10 justify-self-end flex flex-row">
              <a href="https://www.instagram.com/brainchild604/">
                <svg className="h-6 lg:h-16" viewBox="-1 -1 62 62" xmlns="http://www.w3.org/2000/svg" style={{ fill: "white", stroke: "white" }}>
                  <path d="M0 30.01C0 13.37 13.31 0 29.88 0C46.63 0 59.99 13.3 60 29.97C60.01 46.64 46.6099 60.02 29.9199 60C13.3399 59.98 0.01 46.62 0 30.01ZM12.12 30C12.12 33.45 12.09 36.89 12.13 40.34C12.17 44.94 15.03 47.82 19.62 47.84C26.57 47.87 33.52 47.88 40.48 47.84C44.94 47.81 47.83 44.9 47.85 40.41C47.88 33.46 47.89 26.51 47.85 19.55C47.83 15.06 44.94 12.16 40.46 12.13C33.51 12.09 26.56 12.09 19.6 12.13C15.03 12.16 12.17 15.06 12.13 19.66C12.1 23.11 12.12 26.55 12.12 30Z" />
                  <path d="M15.6802 26.399C16.9102 26.399 17.9302 26.389 18.9502 26.399C19.4802 26.409 20.0203 26.449 20.7303 26.479C19.4903 31.689 20.6903 35.959 25.4903 38.579C28.9703 40.479 32.5303 40.189 35.7303 37.819C39.5403 34.999 40.4003 31.129 39.2303 26.539C40.9103 26.539 42.5102 26.539 44.3202 26.539C44.3202 30.439 44.3202 34.359 44.3202 38.269C44.3202 43.769 43.7803 44.309 38.3403 44.309C32.1703 44.309 26.0103 44.319 19.8403 44.309C16.9203 44.299 15.6902 43.049 15.6802 40.089C15.6702 35.619 15.6802 31.159 15.6802 26.399Z" />
                  <path d="M36.2492 30.069C36.1892 33.4789 33.2992 36.2988 29.9192 36.2388C26.4992 36.1788 23.6792 33.2889 23.7392 29.919C23.7992 26.5291 26.7192 23.6792 30.0792 23.7392C33.4492 23.7892 36.2992 26.7091 36.2492 30.069Z" />
                  <path d="M37.8203 22.1211C37.8203 20.231 37.8203 18.401 37.8203 16.4609C39.7203 16.4609 41.5604 16.4609 43.5104 16.4609C43.5104 18.311 43.5104 20.141 43.5104 22.1211C41.7104 22.1211 39.8803 22.1211 37.8203 22.1211Z" />
                </svg>
              </a>
              <a href="https://www.behance.net/brainchild604">
                <svg className="h-6 lg:h-16" viewBox="-1 -1 62 62" xmlns="http://www.w3.org/2000/svg" style={{ fill: "white", stroke: "white" }}>
                  <path d="M30.0501 60C13.5301 60.01 0.110128 46.61 0.0801284 30.08C0.0501284 13.46 13.4401 0 30.0301 0C46.6201 0 60.0302 13.45 60.0102 30.07C59.9902 46.59 46.5701 59.99 30.0501 60ZM10.2302 41.96C15.0102 41.96 19.6101 42.05 24.2001 41.9C25.4601 41.86 26.8101 41.33 27.9401 40.7C30.8401 39.08 31.3701 36.29 31.0801 33.28C30.8501 30.9 29.8801 28.97 27.7101 28.47C28.4601 27.45 29.7301 26.45 30.0201 25.22C31.0601 20.77 28.6101 17.39 24.7901 17.15C20.0501 16.86 15.2901 16.99 10.5401 16.95C10.4601 16.95 10.3802 17.06 10.2402 17.16C10.2302 25.35 10.2302 33.57 10.2302 41.96ZM50.2901 34.23C51.0801 30.55 49.5901 26.45 46.9201 24.68C42.0601 21.48 35.0901 23.64 32.9301 29.02C30.9401 33.97 32.5201 39.44 36.6801 41.43C40.0301 43.03 43.5201 43.03 46.9101 41.48C49.2001 40.43 50.3001 38.54 50.2901 35.91C48.8401 35.91 47.4701 35.91 46.1001 35.91C44.9801 38.25 43.1001 39.05 40.4301 38.33C38.4801 37.8 37.5201 36.42 37.6901 34.22C41.8901 34.23 46.1101 34.23 50.2901 34.23ZM46.6202 21.45C46.6202 20.35 46.6202 19.42 46.6202 18.48C43.1602 18.48 39.8101 18.48 36.4601 18.48C36.4601 19.52 36.4601 20.46 36.4601 21.45C39.8701 21.45 43.1802 21.45 46.6202 21.45Z" />
                  <path d="M16.0293 30.9103C18.5393 30.9103 20.9393 30.8503 23.3293 30.9303C24.8893 30.9903 25.7993 32.2903 25.8293 34.2203C25.8593 35.9903 25.0494 37.3003 23.5894 37.3703C21.1094 37.5003 18.6193 37.4003 16.0393 37.4003C16.0293 35.2503 16.0293 33.1703 16.0293 30.9103Z" />
                  <path d="M16.0195 26.9492C16.0195 25.0191 16.0195 23.2991 16.0195 21.429C18.4895 21.429 20.8896 21.279 23.2597 21.479C24.8097 21.609 24.9796 23.0391 24.9296 24.3191C24.8796 25.5892 24.6596 26.8892 22.9996 26.9392C20.6996 26.9892 18.4095 26.9492 16.0195 26.9492Z" />
                  <path d="M37.5703 30.8003C38.2103 28.0604 40.1303 26.6405 42.4603 27.0304C44.4703 27.3604 45.5803 28.7404 45.4403 30.8003C42.8603 30.8003 40.2703 30.8003 37.5703 30.8003Z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="grid-cols-1 lg:grid-cols-2 text-2xl lg:text-3xl font-extralight lg:font-light border-t-1 border-white grid grid-flow-row items-center">
            <div className="h-full lg:border-r-1 col-span-1 w-full border-white grid items-center">
              <p className="mx-5 lg:mx-10 py-6 lg:py-10 uppercase">
                1020 - 2633 Simpson Rd
                <br />
                Richmond, BC V6X 2P9
              </p>
            </div>
            <div className="col-span-1 w-full h-full grid items-center">
              <p className="mx-5 lg:mx-10 pb-6 lg:py-10 lg:text-center">778 - 998 - 8923</p>
            </div>
          </div>
          <ReactFitText compressor={1.55}>
            <div className="hidden lg:grid lg:py-14 border-t-1 border-white text-center font-semibold w-full h-full items-center">
              <p>BRAINCHILD604@GMAIL.COM</p>
            </div>
          </ReactFitText>
          <ReactFitText compressor={1.6}>
            <div className="grid lg:hidden px-5 py-6 border-t-1 border-white text-center font-semibold w-full items-center">
              <p>BRAINCHILD604@GMAIL.COM</p>
            </div>
          </ReactFitText>
          <div className="space-y-3 lg:space-y-0 py-6 lg:py-10 flex-col lg:flex-row lg:items-center flex justify-center lg:justify-between border-t-1 border-white overflow-hidden">
            {footerList.map((footerItem, index) => {
              return (
                <a className="col-span-1" key={index} href="mailto:brainchild604@gmail.com">
                  <p
                    onMouseEnter={() => setHoverItem(index)}
                    onMouseLeave={() => setHoverItem("")}
                    className="mx-5 lg:mx-10 text-2xl lg:text-3xl font-semibold lg:font-normal lg:overflow-auto"
                  >
                    <span>{footerItem}</span>
                    <motion.svg
                      variants={arrowVariant}
                      initial="idle"
                      animate={hoverItem === index ? "visible" : ""}
                      className="hidden lg:inline h-5 lg:h-8 stroke-2 mx-5 overflow-hidden"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ fill: "none" }}
                    >
                      <path d="M26.1797 27L26.1797 2L2.00128 2" />
                      <path d="M2.00128 27L26.1797 2.11737" />
                    </motion.svg>
                    <motion.svg
                      className="inline lg:hidden h-5 lg:h-8 stroke-2 mx-5 overflow-hidden"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ fill: "none" }}
                    >
                      <path d="M26.1797 27L26.1797 2L2.00128 2" />
                      <path d="M2.00128 27L26.1797 2.11737" />
                    </motion.svg>
                  </p>
                </a>
              );
            })}
          </div>
        </div>
      </AnimeFooterColor>
    </>
  );
};

export default Footer;
