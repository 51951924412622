import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const AnimeFadeIn = (props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { delay = 1 } = props;
  const { duration = 1 } = props;

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: {
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          transition: {
            delay: delay,
            duration: duration,
            type: "tween",
          },
        },
        hidden: {
          clipPath: "polygon(0 0, 100% 0, 100% 0.1%, 0 0.1%)",
          transition: {
            delay: delay,
            duration: duration,
            type: "tween",
          },
        },
      }}
      {...props}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimeFadeIn;
