import React, { useState, useEffect } from "react";
import { sectionVariants } from "./variants";
import { motion, AnimatePresence } from "framer-motion";
import AnimeExpand from "@components/anime/AnimeExpand";
import studioCareerImg from "../../img/studioCareer.png";

const StudioSectionCareer = ({ sectionIndex }) => {
  const [careerData, setCareerData] = useState([]);
  const [openIndex, setOpenIndex] = useState(false);

  useEffect(async () => {
    const list = await fetch("/assets/jobs/jobs.json").then((response) => response.json());
    setCareerData(list);
  }, []);

  return (
    <>
      <motion.div
        variants={sectionVariants}
        initial={false}
        animate={sectionIndex === 4 ? "visible" : "hiddenBottom"}
        transition={{ duration: 1, ease: "easeInOut" }}
        className={`w-screen bg-white ${sectionIndex === 4 ? "z-50" : "fixed z-0"}`}
      >
        <div className="mx-5 lg:mx-10 lg:py-20 grid-cols-1 lg:grid-cols-2 lg:h-screen grid grid-flow-row items-center gap-10">
          <div className="pt-52 lg:pt-0 pb-14 lg:pb-0 col-span-1 leading-100 lg:leading-120">
            WE’RE ALWAYS
            <br />
            RECRUITING
            <br />
            NEW CREATIVES
          </div>
          <div className="text-4xl lg:text-5xl col-span-1 font-medium">
            LET’S MAKE
            <br />
            DOPE SH*T
            <br />
            AND GET PAID
          </div>
        </div>

        <div className="mx-5 lg:mx-10 grid-cols-1 lg:grid-cols-2 lg:gap-10 grid grid-flow-row items-center py-20">
          <div className="col-span-1">
            <AnimeExpand className="w-full">
              <img className="object-cover" src={studioCareerImg} alt="career"></img>
            </AnimeExpand>
          </div>
          <div className="col-span-1">
            <p className="text-xl font-extralight">[email us]</p>
            <a className="text-2xl lg:text-3xl font-medium cursor-pointer hover:underline" href="mailto:brainchild604@gmail.com">
              brainchild604@gmail.com
            </a>
          </div>
          <div className="col-span-1">
            <p className="text-xs lg:text-sm w-2/3 lg:w-1/2 py-20 lg:py-10">
              If you’re interested in working with us or learning more, drop us a note, porfolio link, or resume. We’ll take anything you’ve got.
            </p>
            <hr className="border-black" />

            {careerData &&
              careerData.map((data, index) => {
                return (
                  <div key={index}>
                    <div className="py-5 lg:py-10 flex flex-row justify-between items-center">
                      <p className="text-xl font-medium">{data.title}</p>
                      {openIndex !== index && (
                        <p onClick={() => setOpenIndex(index)} className="px-2 text-2xl font-light cursor-pointer text-center">
                          +
                        </p>
                      )}
                      {openIndex === index && (
                        <p onClick={() => setOpenIndex(false)} className="px-2 text-2xl font-light cursor-pointer text-center">
                          -
                        </p>
                      )}
                    </div>

                    {openIndex === index && (
                      <AnimeExpand delay={0} duration={0.5}>
                        <div className="lg:pr-40">
                          <div className="mb-10" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                        </div>
                      </AnimeExpand>
                    )}
                    <motion.hr
                      variants={{
                        open: {
                          y: [-100, 0],
                          transition: {
                            duration: 0.5,
                            type: "spring",
                          },
                        },
                        close: { y: 0 },
                      }}
                      animate={openIndex === index ? "open" : "close"}
                      className="border-black"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default StudioSectionCareer;
